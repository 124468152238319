import axios from "axios";
import { getApiUrl, buildRequestHeaders } from "utils/Api";
import { CATALOGUE_URL_CONSTANTS } from "utils/Constants";

const COUNTRY_ENDPOINT = "catalogue/catalogue/backoffice";

export const searchCountry = async (filters, accessToken) => {
  return await axios.get(`${getApiUrl()}${CATALOGUE_URL_CONSTANTS.getListCountriesCatalogue}`, {
    headers: buildRequestHeaders(accessToken),
    params: filters,
  });
};
