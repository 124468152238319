import { useAuth0 } from "@auth0/auth0-react";
import jwt from "jwt-decode";

const Auth0Helper = () => {
  const { getAccessTokenSilently } = useAuth0();

  const scopesCondiditions = ["payin"];

  const getEnabledCountries = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledCountries.split(",");
  };

  const getEnabledMerchantForPayment = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledMerchantsPayin.split(",");
  };

  const getRoles = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    const roles = tokenInfo.userRol;

    return roles;
  };

  const validateUserRole = async (roleNeededList) => {
    if (!roleNeededList) {
      return true;
    }

    const userRoles = await getRoles();

    const valid = roleNeededList ? roleNeededList.some((x) => userRoles.includes(x)) : false;

    return valid;
  };

  const getPayoutEnabledCountries = async () => {
    const accessToken = await getAccessTokenSilently();

    const tokenInfo = jwt(accessToken);
    return tokenInfo.enabledCountriesPayOut.split(",");
  };

  return {
    validateUserRole,
    getEnabledCountries,
    getEnabledMerchantForPayment,
    getPayoutEnabledCountries,
  };
};

export default Auth0Helper;

export const AUTH0_SCOPES = ["profile", "email", "payin:catalogue"];
