import payinIcon from "../assets/img/payinIcon.png";
import payoutIcon from "../assets/img/payoutIcon.png";
import conciliationIcon from "../assets/img/conciliationIcon.png";
import auditIcon from "../assets/img/auditIcon.png";
import middlewareIcon from "../assets/img/middlewareIcon.png";
import smartRoutingIcon from "../assets/img/smartRoutingIcon.png";
import logoMore from "../assets/img/img-001.png";

export const GLOBAL_CONSTANTS = {
  MORE_LOGO_PATH: logoMore,
  API_OAUTH_DOMAIN: process.env.REACT_APP_API_OAUTH_DOMAIN,
  API_OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
};

export const CATALOGUE_URL_CONSTANTS = {
  getListCountriesCatalogue: "catalogue/catalogue/backoffice/getListCountries?size=999999&page=0",
};

export const LOGIN_BOXES_LOGOS = {
  payinIcon,
  payoutIcon,
  auditIcon,
  conciliationIcon,
  middlewareIcon,
  smartRoutingIcon,
};

export const ALERT_TYPES = {
  success: "success",
  error: "danger",
};

export const MERCHANT_AGGREGATOR_ROLES = {
  aggregator: 1,
  merchant: 2,
  merchantAggregator: 3,
};

export const MANDATORY_FIELD_TYPES = {
  string: 1,
  number: 2,
  date: 3,
  select: 4,
};

export const HTTP_STATUS_CODES = {
  NoContent: 204,
};

// COLORES
export const ColorOrangeMoreMT = "#f07c00";
