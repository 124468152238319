import axios from "axios";
import { getPayoutApiUrl } from "utils/Api";
import { buildRequestHeaders } from "utils/Api";
import { getApiUrl } from "utils/Api";

export const sendVerificationCodeEmail = async (accessToken) => {
  return axios.post(
    `${getPayoutApiUrl()}security/api/auth/mfa/initiate`,
    {},
    {
      headers: buildRequestHeaders(accessToken),
    }
  );
};

export const validateVerificationCode = async (accessToken, requestBody) => {
  return axios.post(`${getPayoutApiUrl()}security/api/auth/mfa/validate`, requestBody, {
    headers: buildRequestHeaders(accessToken),
  });
};
