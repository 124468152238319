import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "components/pages/Login/Login";
import "./styles.scss";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomLoader from "components/shared/CustomLoader";
import React, { useState } from "react";
import { AlertProvider } from "components/shared/AlertContext";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAuthData } from "store/slices/authSlice";
import { resetAuthData } from "store/slices/authSlice";
import PasswordExpiredComponent from "components/shared/PasswordExpiredComponent";
import MFAComponent from "components/shared/MFA/MFAComponent";

const App = () => {
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [isMfaSuccess, setIsMfaSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [isAppLoading, setIsAppLoading] = useState(true);

  const { isLoading, loginWithRedirect, user, isAuthenticated, error, getAccessTokenSilently } =
    useAuth0();
  const dispatch = useDispatch();

  const handleLoginAgain = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const login = async () => {
      try {
        if (!isLoading && !isAuthenticated && !user) {
          if (error && error.message?.includes("Your password has expired. Please reset it.")) {
            setUserEmail(error.message.split("|")[1]);
            setPasswordExpired(true);
            setIsAppLoading(false);
          } else {
            await handleLoginAgain();
          }
        } else if (isAuthenticated) {
          setIsAppLoading(false);
          await setRolesAndPermission();
        }
      } catch (err) {
        console.error("Error during authentication:", err);
      }
    };

    login();
  }, [isLoading, isAuthenticated, user, error]);

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const setRolesAndPermission = async () => {
    try {
      const token = await getAccessTokenSilently({
        cacheMode: "cache-only", // Solo usa el token en caché si está disponible.
      });
      if (token) {
        const decode = jwtDecode(token);
        dispatch(setAuthData(decode));
      } else {
        dispatch(resetAuthData());
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      // Puedes manejar un reintento o redirigir al login si es necesario
      if (error.error === "login_required") {
        await handleLoginAgain();
      }
    }
  };

  if (isAppLoading)
    return (
      <div style={loaderStyle}>
        <CustomLoader />
      </div>
    );
  if (passwordExpired) {
    return (
      <PasswordExpiredComponent
        setModal={setPasswordExpired}
        userEmail={userEmail}
        modal={passwordExpired}
      />
    );
  }
  if (!isMfaSuccess) {
    return <MFAComponent onMFAVerified={() => setIsMfaSuccess(true)} />;
  }

  return (
    isAuthenticated && (
      <BrowserRouter>
        <AlertProvider>
          <React.Suspense
            fallback={
              <div style={loaderStyle}>
                <CustomLoader />
              </div>
            }
          >
            <Switch>
              <Route path="/login" render={() => <Login />} />
              <Redirect to="/login" />
            </Switch>
          </React.Suspense>
        </AlertProvider>
      </BrowserRouter>
    )
  );
};

export default App;
