import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "components/pages/Login/Login";
import "./styles.scss";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomLoader from "components/shared/CustomLoader";
import React, { useState } from "react";
import { AlertProvider } from "components/shared/AlertContext";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAuthData } from "store/slices/authSlice";
import { resetAuthData } from "store/slices/authSlice";
import PasswordExpiredComponent from "components/shared/PasswordExpiredComponent";

const App = () => {
  const [passwordExpired, setPasswordExpired] = useState(false);
  const { isLoading, loginWithRedirect, user, isAuthenticated, error, getAccessTokenSilently } =
    useAuth0();
  const dispatch = useDispatch();

  const handleLoginAgain = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const login = async () => {
      try {
        if (!isLoading && !isAuthenticated && !user) {
          if (error?.message === "Your password has expired.  Please reset it.") {
            setPasswordExpired(true);
          } else {
            await handleLoginAgain();
          }
        } else if (isAuthenticated) {
          await setRolesAndPermission();
        }
      } catch (err) {
        console.error("Error during authentication:", err);
      }
    };

    login();
  }, [isLoading, isAuthenticated, user, error]);

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const setRolesAndPermission = async () => {
    try {
      const token = await getAccessTokenSilently({
        cacheMode: "cache-only", // Solo usa el token en caché si está disponible.
      });
      if (token) {
        const decode = jwtDecode(token);
        dispatch(setAuthData(decode));
      } else {
        dispatch(resetAuthData());
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      // Puedes manejar un reintento o redirigir al login si es necesario
      if (error.error === "login_required") {
        await handleLoginAgain();
      }
    }
  };

  if (isLoading)
    return (
      <div style={loaderStyle}>
        <CustomLoader />
      </div>
    );
  if (passwordExpired) {
    return <PasswordExpiredComponent setModal={setPasswordExpired} modal={passwordExpired} />;
  }

  return (
    isAuthenticated && (
      <BrowserRouter>
        <AlertProvider>
          <React.Suspense
            fallback={
              <div style={loaderStyle}>
                <CustomLoader />
              </div>
            }
          >
            <Switch>
              <Route path="/login" render={() => <Login />} />
              <Redirect to="/login" />
            </Switch>
          </React.Suspense>
        </AlertProvider>
      </BrowserRouter>
    )
  );
};

export default App;
