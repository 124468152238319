import axios from "axios";
import { getApiUrl, buildRequestHeaders } from "utils/Api";

const COUNTRY_ENDPOINT = "catalogue/catalogue/backoffice";

export const searchCountry = async (filters, accessToken) => {
  return await axios.get(`${getApiUrl()}${COUNTRY_ENDPOINT}/getListCountries`, {
    headers: buildRequestHeaders(accessToken),
    params: filters,
  });
};

