import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import "./MFAComponent.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { GLOBAL_CONSTANTS } from "utils/Constants";
import { Oval } from "react-loader-spinner";
import { sendVerificationCodeEmail, validateVerificationCode } from "./Api/MFAComponentApi";
import { useAlert } from "../AlertContext";
import { ALERT_TYPES } from "utils/Constants";
import CustomLoader from "../CustomLoader";

const MFA_REQUIRED_STATUS = "MFA_REQUIRED";
const MFA_NOT_REQUIRED_STATUS = "MFA_NOT_REQUIRED";

const MFAComponent = (props) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [mfaError, setMfaError] = useState();
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const showNotification = useAlert();

  const handleInitiateMfa = async () => {
    const accessToken = await getAccessTokenSilently();
    sendVerificationCodeEmail(accessToken)
      .then((result) => {
        if (result.data.status == MFA_NOT_REQUIRED_STATUS) {
          props.onMFAVerified(true);
        }
      })
      .catch((error) => {
        showNotification(error?.response?.data?.message ?? error.message, ALERT_TYPES.error);
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  const handleInputChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value.slice(0, 1);
    setCode(newCode);

    if (value.trim() && index < code.length - 1) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    const newCode = [...code];

    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < 6) {
        newCode[index + i] = pastedData[i];
      }
    }

    setCode(newCode);

    const nextIndex = index + pastedData.length < 6 ? index + pastedData.length : 5;
    document.getElementById(`code-input-${nextIndex}`).focus();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      document.getElementById(`code-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    setIsVerifying(true);
    setMfaError(null);

    const accessToken = await getAccessTokenSilently();
    const requestBody = {
      code: code.join(""),
    };

    validateVerificationCode(accessToken, requestBody)
      .then((result) => {
        props.onMFAVerified(true);
      })
      .catch((error) => {
        setMfaError(error?.response?.data?.message ?? error.message);
        console.error(error);
      })
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const handleLogOut = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  useEffect(() => {
    handleInitiateMfa();
  }, []);

  useEffect(() => {
    if (code.join("").trim().length == 6) {
      handleSubmit();
    }
  }, [code]);

  if (isPageLoading) {
    return <CustomLoader className="loader-center-content" />;
  }

  return (
    <Row className="pt-5">
      <Col md={3} />
      <Col md={6}>
        <Card className="text-center pt-4 pb-2 card-login">
          <CardBody className="mt-3">
            <div style={{ maxWidth: "500px", margin: "0 auto", textAlign: "center" }}>
              <img src={GLOBAL_CONSTANTS.MFA_ICON_PATH} alt="MFA Icon" style={{ width: "350px" }} />
              <h3>Verify your code</h3>
              <p className="mb-0">{`Enter the security code sent to "${user.email}"`}</p>
              <p className="font-weight-bolder">
                If you didn't get de confirmation code, please{" "}
                <Button color="primary" size="sm" onClick={handleInitiateMfa}>
                  Send again
                </Button>
              </p>
              <div className="mfa-input">
                {code.map((digit, index) => (
                  <Input
                    key={index}
                    id={`code-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={(e) => handlePaste(e, index)}
                    className="mfa-input-digit"
                  />
                ))}
              </div>
              {mfaError && <p className="error-message">{mfaError}</p>}
              <div className="mt-4">
                <Button
                  className="mfa-verify-button"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isVerifying}
                >
                  {!isVerifying ? (
                    "Validate"
                  ) : (
                    <>
                      Validating...
                      <Oval
                        height={25}
                        width={25}
                        color="#dddddd"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#dddddd"
                        strokeWidth={6}
                        strokeWidthSecondary={6}
                      />
                    </>
                  )}
                </Button>
              </div>
              <div>
                <Button size="sm" color="primary" onClick={handleLogOut}>
                  Logout
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={3} />
    </Row>
  );
};

MFAComponent.propTypes = {
  onMFAVerified: PropTypes.func,
};

export default MFAComponent;
