import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRol: [],

  isPayinAccess: false,
  isPayoutAccess: false,
  isAuditAccess: false,
  isConciliationAccess: false,
  isMiddlewareAccess: false,
  isSmartRoutingAccess: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const decode = action.payload;
      const { userRol } = decode;

      state.userRol = userRol;

      state.isPayinAccess =
        userRol.includes("BACKOFFICE_PAYIN_ACCESS") || userRol.includes("ADMIN_PAYIN");
      state.isPayoutAccess = userRol.includes("BACKOFFICE_PAYOUT_ACCESS");
      state.isAuditAccess =
        userRol.includes("BACKOFFICE_AUDITS_ACCESS") || userRol.includes("ADMIN_AUDIT");
      state.isConciliationAccess =
        userRol.includes("BACKOFFICE_CONCILIATION_ACCESS") ||
        userRol.includes("ADMIN_CONCILIATION");
      state.isMiddlewareAccess = userRol.includes("BACKOFFICE_MIDDLEWARE_ACCESS");
      state.isSmartRoutingAccess =
        userRol.includes("FRONTEND_SMART_ROUTING_ACCESS") ||
        userRol.includes("ADMIN_SMART_ROUTING");
    },
    resetAuthData: (state) => {
      state = initialState;
    },
  },
});

export const { setAuthData, resetAuthData } = authSlice.actions;

export default authSlice.reducer;
