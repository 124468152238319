import React, { useState, useEffect, lazy } from "react";
import { Container, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setEnabledCountries } from "../../../store/slices/countryInfo";
import "./style.scss";
import { GLOBAL_CONSTANTS, LOGIN_BOXES_LOGOS } from "utils/Constants";
import Auth0Helper from "utils/Auth0Helper";
import { useAuth0 } from "@auth0/auth0-react";
import { searchCountry } from "../Login/Api/CountryApi";
import { buildEnabledCountriesOptions } from "utils/Utils";
import { buildPayoutCountriesOptions } from "utils/Utils";

const LoginCard = lazy(() => import("./LoginCard/LoginCard"));
const CustomLoader = lazy(() => import("components/shared/CustomLoader"));
const Navbar = lazy(() => import("components/shared/Navbars/Navbar.js"));

const Login = (props) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [payinCountryOptions, setPayinCountryOptions] = useState();
  const [payoutCountryOptions, setPayoutCountryOptions] = useState();
  const [columnSize, setColumnSize] = useState();
  const [rowSize, setRowSize] = useState("w-50");
  const [accessQuantity, setAccessQuantity] = useState(0);

  const { getEnabledCountries, getPayoutEnabledCountries } = Auth0Helper();
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authSlice);
  const {
    isPayinAccess,
    isPayoutAccess,
    isAuditAccess,
    isConciliationAccess,
    isMiddlewareAccess,
    isSmartRoutingAccess,
  } = authState;

  useEffect(() => {
    initializeComponent();
  }, []);

  useEffect(() => {
    const visibleColumnsCount = [
      isPayinAccess,
      isPayoutAccess,
      isAuditAccess,
      isConciliationAccess,
      isMiddlewareAccess,
      isSmartRoutingAccess,
    ].filter(Boolean).length;

    setAccessQuantity(visibleColumnsCount);

    setRowSize(visibleColumnsCount < 2 ? "w-50" : "w-100");
    const size = visibleColumnsCount > 0 ? Math.floor(12 / visibleColumnsCount) : 12;

    setColumnSize(size);
  }, [
    isPayinAccess,
    isPayoutAccess,
    isAuditAccess,
    isConciliationAccess,
    isMiddlewareAccess,
    isSmartRoutingAccess,
  ]);

  const initializeComponent = async () => {
    const payinCountries = await getEnabledCountries();
    const payoutCountries = await getPayoutEnabledCountries();

    const accessToken = await getAccessTokenSilently();
    await searchCountry({ size: 999999, page: 0 }, accessToken)
      .then((result) => {
        let payinCountriesOptions = buildEnabledCountriesOptions(
          result.data.content,
          payinCountries,
          true // este bool es necesario para que pueda formar las options y mostrar las banderas
        );

        let payoutCountriesOptions = buildPayoutCountriesOptions(
          result.data.content,
          payoutCountries,
          true
        );

        dispatch(setEnabledCountries(payinCountries));
        setPayinCountryOptions(payinCountriesOptions);
        setPayoutCountryOptions(payoutCountriesOptions);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  if (isLoading) {
    return <CustomLoader className="loader-center-content" />;
  }

  return (
    <>
      <Navbar {...props} onToggleSideBar={toggleSidebar} isLoginView={true} />
      <Container fluid className="text-center mb-5 mt-5">
        <img src={GLOBAL_CONSTANTS.MORE_LOGO_PATH} alt="moreLogo" className="w-25" />
      </Container>
      <Container fluid>
        {accessQuantity > 0 ? (
          <Row className="justify-content-center">
            {isPayinAccess && (
              <Col md={5} xs={12}>
                <LoginCard
                  logo={GLOBAL_CONSTANTS.MORE_LOGO_PATH}
                  label="Payin Backoffice"
                  buttonLabel="Payin backoffice"
                  pathUrl=""
                  comboOptions={payinCountryOptions}
                  appName="payin"
                  icon={LOGIN_BOXES_LOGOS.payinIcon}
                />
              </Col>
            )}
            {isPayoutAccess && (
              <Col md={5} xs={12}>
                <LoginCard
                  logo={GLOBAL_CONSTANTS.MORE_LOGO_PATH}
                  label="Payout Backoffice"
                  buttonLabel="Payout backoffice"
                  pathUrl=""
                  comboOptions={payoutCountryOptions}
                  appName="payout"
                  icon={LOGIN_BOXES_LOGOS.payoutIcon}
                />
              </Col>
            )}
            {isAuditAccess && (
              <Col md={5} xs={12}>
                <LoginCard
                  logo={GLOBAL_CONSTANTS.MORE_LOGO_PATH}
                  label="Audits"
                  buttonLabel="Audits application"
                  pathUrl=""
                  appName="audits"
                  icon={LOGIN_BOXES_LOGOS.auditIcon}
                />
              </Col>
            )}
            {isConciliationAccess && (
              <Col md={5} xs={12}>
                <LoginCard
                  logo={GLOBAL_CONSTANTS.MORE_LOGO_PATH}
                  label="Conciliations"
                  buttonLabel="Conciliations application"
                  pathUrl=""
                  appName="conciliations"
                  icon={LOGIN_BOXES_LOGOS.conciliationIcon}
                />
              </Col>
            )}
            {isMiddlewareAccess && (
              <Col md={5} xs={12}>
                <LoginCard
                  logo={GLOBAL_CONSTANTS.MORE_LOGO_PATH}
                  label="Middleware"
                  buttonLabel="Middleware application"
                  pathUrl=""
                  appName="middleware"
                  icon={LOGIN_BOXES_LOGOS.middlewareIcon}
                />
              </Col>
            )}
            {isSmartRoutingAccess && (
              <Col md={5} xs={12}>
                <LoginCard
                  logo={GLOBAL_CONSTANTS.MORE_LOGO_PATH}
                  label="Smart Routing"
                  buttonLabel="Smart Routing application"
                  pathUrl=""
                  appName="smartRouting"
                  comboOptions={payinCountryOptions}
                  icon={LOGIN_BOXES_LOGOS.smartRoutingIcon}
                />
              </Col>
            )}
          </Row>
        ) : (
          <h5>You don't have access to any backoffice</h5>
        )}
      </Container>
    </>
  );
};

export default Login;
