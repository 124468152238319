const currentHost = `${window.location.protocol}//${window.location.hostname}`;

export const getApiUrl = () => {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/api/";
  }

  return process.env.REACT_APP_BACKEND;
};

export const getPayoutApiUrl = () => {
  if (process.env.REACT_APP_USE_PROXY === "true") {
    return "/payout-api/";
  }

  return process.env.REACT_APP_PAYOUT_BACKEND;
};

export const buildRequestHeaders = (accessToken, includeTenant = true) => {
  const tenant = localStorage.getItem("pai");

  let headers = {
    "Access-Control-Allow-Origin": `${currentHost}`,
    "Access-Control-Allow-Access-Control-Allow-Credentials": "true",
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    Authorization: `Bearer ${accessToken}`,
  };

  if (includeTenant) {
    headers = { ...headers, "X-TENANT-ID": tenant };
  }

  return headers;
};
